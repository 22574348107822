<template>
  <section
    class="hero is-fullheight is-pulled-up"
    :class="[$experian ? 'has-background-info' : 'has-background-dark']"
  >
    <div class="hero-body mobile-background" :style="hero">
      <div class="container">
        <LoginForm />
      </div>
    </div>
  </section>
</template>

<script>
import { heroStyle } from 'modules/ui/services'

export default {
  name: 'Login',
  components: {
    LoginForm: () => import('../components/LoginForm')
  },
  computed: {
    hero() {
      return this.$mq !== 'mobile' ? heroStyle() : { background: 'none' }
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  max-width: 30rem !important
</style>
